<template>
  <div :class="{'opacity-30': !getSaveLink.is_deep_link_enabled}" v-if="toggle">
    <p class="text !text-[#202324] font-bold">Deep Linking</p>
    <div v-if="is_deep_link_allowed" class="flex flex-col items-center justify-center w-full h-[21rem]">
      <div  class="bg-[#FBFFFA] rounded-[100%] flex flex-col items-center justify-center mt-[1.25rem] w-64 h-64">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-[2.375rem] h-[1.875rem] mb-[1.25rem]" viewBox="0 0 38 30" fill="none">
          <path d="M35.5 3L10.3269 27L2.5 18.5844" stroke="#3ABA6D" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="text text-center">Deep Linking has been <br><span class="text !text-[#3ABA6D] font-bold">Successfully Activated</span> <br> on the link.</p>
      </div>
    </div>
    <div v-else class="flex flex-col items-center justify-center w-full h-[21rem]">
      <div  class="bg-[#FFF5F5] rounded-[100%] flex flex-col items-center justify-center mt-[1.25rem] w-64 h-64">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-[1.875rem] h-[1.875rem] mb-[1.25rem]" viewBox="0 0 32 30" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.68847 4.80943C0.689156 3.85668 0.651405 2.27422 1.60415 1.2749C2.5569 0.275583 4.13936 0.237832 5.13868 1.19058L16.0002 11.546L26.8618 1.19058C27.8611 0.237832 29.4436 0.275583 30.3963 1.2749C31.3491 2.27422 31.3113 3.85668 30.312 4.80943L19.6232 15.0001L30.3118 25.1906C31.3111 26.1433 31.3488 27.7258 30.3961 28.7251C29.4433 29.7244 27.8609 29.7622 26.8616 28.8094L16.0002 18.4542L5.13891 28.8094C4.13959 29.7622 2.55713 29.7244 1.60438 28.7251C0.651631 27.7258 0.689382 26.1433 1.6887 25.1906L12.3773 15.0001L1.68847 4.80943Z" fill="#C13A52"/>
        </svg>
        <p class="text text-center">Deep Linking <br><span class="text !text-[#C13A52] font-bold">Unsuccessfully</span> <br> on the link.</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DeepLinksComponentV2',
  props: [
    'toggle',
    'is_deep_link_allowed',
    'url',
    'self',
    'deepLinksBrandsList',
    'campaignId'
  ],
  methods: {
    /**
     * This method is responsible for validating deep linking
     * @returns {*|boolean}
     */
    validateDeepLink() {
      if(this.toggle && !this.is_deep_link_allowed) {
        this.$store.dispatch('toastNotification', {message: 'Deep Link Validation failed.', type: 'error'})
        return false
      }
      return true
    }
  }
}
</script>
